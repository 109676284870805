<template>
  <!-- 订单收款 -->
  <div>
    <div class="revenue-title flex flex-jsb">
      <div class="flex" style="align-items: center">
        <div class="ft-bold" v-if="
          (payReportdata.start_time != '' || payReportdata.end_time != '') &&
          isShowP
        ">
          时间：{{ payReportdata.start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{
              payReportdata.end_time
          }}
        </div>
      </div>
    </div>
    <table width="100%" style="border-collapse: collapse; margin-top: 2.5rem" border="0" cellspacing="0"
      cellpadding="0">
      <thead>
        <tr bgcolor="#F2F2F2" class="thead-tr ft-22 color-333 text-center ft-bold"
          style="height: 3.8rem;">
          <td class="tabletitle" rowspan="2">时间</td>
          <td class="tabletitle" colspan="2">现金</td>
          <td class="tabletitle" colspan="2">会员余额</td>
          <td class="tabletitle" colspan="2">微信</td>
          <td class="tabletitle" colspan="2">支付宝</td>
          <td class="tabletitle" colspan="2">云闪付</td>
        </tr>
        <tr bgcolor="#F2F2F2" class="thead-tr ft-22 color-333 text-center" style="height: 3.8rem">
          <td class="tabletd">笔数</td>
          <td class="tabletd">实收金额</td>
          <td class="tabletd">笔数</td>
          <td class="tabletd">实收金额</td>
          <td class="tabletd">笔数</td>
          <td class="tabletd">实收金额</td>
          <td class="tabletd">笔数</td>
          <td class="tabletd">实收金额</td>
          <td class="tabletd">笔数</td>
          <td class="tabletd">实收金额</td>
        </tr>
      </thead>
      <tbody style="overflow-y: scroll" v-if="isShowP">
        <tr :class="['color-333', 'ft-22', 'text-center', 'tbody-tr']" v-for="(item, index) in payList" :key="index">
          <td>{{ item.day_time }}</td>
          <td>{{ item.xj_order_num }}</td>
          <td>{{ item.xj_actual_price }}</td>
          <td>{{ item.hy_order_num }}</td>
          <td>{{ item.hy_actual_price }}</td>
          <td>{{ item.wx_order_num }}</td>
          <td>{{ item.wx_actual_price }}</td>
          <td>{{ item.zfb_order_num }}</td>
          <td>{{ item.zfb_actual_price }}</td>
          <td>{{ item.sb_order_num }}</td>
          <td>{{ item.sb_actual_price }}</td>
        </tr>
        <div class="mg-tp30 content" v-if="payList.length == 0">
          <div class="nullStyle">
            <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
          </div>
        </div>
      </tbody>
    </table>
    <div v-if="isShowP" class="flex mg-tp40" style="justify-content:flex-end;">
      <div class="pagination pay-page-box-class" v-if="payList.length != 0">
        <van-pagination v-model="pageform.page" :total-items="pageform.count" :items-per-page="pageform.limit"
          mode="simple" force-ellipses style="background-color: #f2f2f2" @change="nextpage"
          :class="{'pagination-text-hidden': pageOptions.length > 0,}" />
        <!-- <van-dropdown-menu v-if="pageOptions.length > 0" direction="up" class="page-box-dropdown" ref="pageDropdown" :overlay="false" active-color="#1989fa">
          <van-dropdown-item v-model="choose_page" :options="pageOptions" @change="handleChangePage" />
        </van-dropdown-menu> -->
      </div>
    </div>

    <table width="100%" style="border-collapse: collapse;" border="0" cellspacing="0"
      cellpadding="0" v-if="payList.length != 0">
      <tbody>
        <tr :class="['color-333', 'ft-22', 'text-center', 'tbody-tr']">
          <td>合计：</td>
          <td class="ft-bold">{{ paytotalList.xj_order_num }}</td>
          <td class="ft-bold">{{ paytotalList.xj_actual_price }}</td>
          <td class="ft-bold">{{ paytotalList.hy_order_num }}</td>
          <td class="ft-bold">{{ paytotalList.hy_actual_price }}</td>
          <td class="ft-bold">{{ paytotalList.wx_order_num }}</td>
          <td class="ft-bold">{{ paytotalList.wx_actual_price }}</td>
          <td class="ft-bold">{{ paytotalList.zfb_order_num }}</td>
          <td class="ft-bold">{{ paytotalList.zfb_actual_price }}</td>
          <td class="ft-bold">{{ paytotalList.sb_order_num }}</td>
          <td class="ft-bold">{{ paytotalList.sb_actual_price }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>
<script>
export default {
  name: "salesDetails",
  data() {
    return {
      payList: [],
      paytotalList: {},
      pageform: {
        page: 1,
        limit: 6,
        count: 0,
      },
      pageOptions: [],
      choose_page: 1
    };
  },
  props: {
    payReportdata: {
      type: Object,
    },
    queryForm: {
      type: Object,
    },
    isShowP: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChangePage(e) {
      if (this.pageform.page != e) {
        this.pageform.page = e;
        this.nextpage();
      }
    },
    setData() {
      this.pageOptions = [];
      this.payList = this.payReportdata.list;
      this.paytotalList = this.payReportdata.total_info;
      this.pageform.page = Number(this.payReportdata.page);
      this.pageform.limit = Number(this.payReportdata.limit);
      this.pageform.count = Number(this.payReportdata.count);
      this.choose_page = this.pageform.page;
    },
    nextpage() {
      let data = {
        page: this.pageform.page,
        limit: this.pageform.limit,
        start_time: this.payReportdata.start_time,
        end_time: this.payReportdata.end_time,
      };
      this.$emit("nextpay", data);
      this.payList = [];
      this.paytotalList = {};
      this.choose_page = this.pageform.page;
    },
  },
  watch: {
    payReportdata(newV, oldV) {
      this.payReportdata = newV;
      this.setData();
    },
  },
  mounted() {
    if (this.isShowP) {
      this.setData();
    }
  },
};
</script>
<style lang="less" scoped>
.pay-page-box-class {
  position: relative;
  /deep/.van-pagination {
    font-size: 1.9rem;
  }
  .pagination-text-hidden {
    /deep/.van-pagination__page-desc {
      color: #f2f2f2 !important;
    }
  }
  .page-box-dropdown {
    position: absolute;
    top: 0;
    height: 40px;
    background: #f2f2f2;
    width: 31.5%;
    left: 34%;
    color: #646566;
    /deep/.van-dropdown-item {
      width: 3rem;
      left: -0.1rem;
      height: 40vh;
      top: auto;
      position: absolute !important;
      bottom: 40px !important;
    }
    /deep/.van-dropdown-item__content {
      box-shadow: 0 0 0.07rem 0.07rem rgba(229,229,229,0.5);
      max-height: 100%;
      margin: 0 0.1rem;
      width: calc(100% - 0.2rem);
    }
    /deep/.van-dropdown-menu__bar {
      height: 100%;
      background: #f2f2f2;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      color: #646566;
      font-size: 0.26rem;
      padding: 0;
    }
    /deep/.van-dropdown-menu__title--down::after,
    /deep/.van-dropdown-menu__title--active::after {
      display: none;
    }
    /deep/.van-cell {
      font-size: 0.34rem;
      padding: 0.25rem 0.25rem;
    }
    /deep/.van-cell__value {
      flex: none;
    }
  }
}
.revenue-title {
  font-size: 2.6rem;
  color: #333333;
  margin-top: 4rem;
}

.tabletd {
  border: 0.15rem rgba(0, 0, 0, 0.06) solid;
}

table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;

  td {
    word-break: break-all;
  }
}

table {
  background: white;
  position: relative;
  display: table;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    display: block;
    width: 0.6rem;
    height: 0.5rem;
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 0.5rem;
    min-width: 0.4rem;
    border-radius: 0.8rem;
    background-color: rgb(217, 217, 217);
  }

  thead {
    width: calc(100% - 0.6rem);
  }

  thead td {
    border: 0.15rem rgba(0, 0, 0, 0.06) solid;
  }

  tbody {
    overflow-y: scroll;
    display: block;
    height: calc(85vh - 37.45rem);
    width: 100%;
    overflow-x: hidden;
  }

  .tbody-tr {
    overflow-y: scroll;
    height: 7.5rem;
    width: 100%;

    &.odd {
      background: #fef7ef;
    }

    td {
      border: 0.15rem rgba(0, 0, 0, 0.06) solid;
    }
  }
}

.pagination {
  width: 30%;
  // float: right;
}

/deep/.van-pagination__item--active {
  background-color: #fe724f;
}

.nullStyle {
  padding-top: 2rem;
  text-align: center;

  img {
    margin: 0 auto;
    width: 2.53rem;
    height: 1.77rem;
  }
}

.tabletitle {
  border: 0.15rem rgba(0, 0, 0, 0.06) solid;
}

.total {
  height: 7.5rem;
  display: flex;
  width: 20.75rem;
  justify-content: center;
  align-items: center;
  border: 0.15rem rgba(0, 0, 0, 0.06) solid;
  word-break: break-all;
  text-align: center;
  font-weight: bold;
}

.border-rt-none {
  border-right: none !important;
}
</style>