<template>
  <!-- 商品销量 -->
  <div>
    <div class="revenue-title flex flex-jsb">
      <div class="flex" style="align-items: center">
        <div
          v-if="
            (dishData.start_time != '' || dishData.end_time != '') && isShowP
          "
          class="ft-bold"
        >
          时间：{{ dishData.start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{
            dishData.end_time
          }}
        </div>
      </div>
    </div>
    <div class="flex flex-ac mg-tp25">
      <input
        type="text"
        class="search"
        placeholder="请输入商品名称"
        v-model="dish_ranking"
        @keyup.enter="search"
      />
      <button class="search-button" @click="search">搜 索</button>
    </div>
    <table
      width="100%"
      style="border-collapse: collapse;"
      border="0"
      cellspacing="0"
      cellpadding="0"
      class="mg-tp25"
    >
      <thead>
        <tr
          bgcolor="#F2F2F2"
          class="thead-tr ft-22"
          style="height: 7.5rem;"
        >
          <td :class="['color-333', 'ft-22', 'text-center', 'foodname', 'ft-bold']" style="padding-left:1rem;">
            商品名称
          </td>
          <td :class="['color-333', 'ft-22', 'text-center', 'ft-bold']">商品分类</td>
          <td :class="['color-333', 'ft-22', 'text-center', 'ft-bold']">销售均价</td>
          <td :class="['color-333', 'ft-22', 'text-center', 'ft-bold']">
            销售数量
            <span class="caret-wrapper">
              <i
                :class="[
                  'sort-caret',
                  'ascending',
                  numascend ? 'numascending' : '',
                ]"
                @click.stop="Numberascending"
              ></i>
              <i
                :class="[
                  'sort-caret',
                  'descending',
                  numdescend ? 'numdescending' : '',
                ]"
                @click.stop="Numberdescending"
              ></i>
            </span>
          </td>
          <td :class="['color-333', 'ft-22', 'text-center', 'ft-bold']">
            销售金额
            <span class="caret-wrapper">
              <i
                :class="[
                  'sort-caret',
                  'ascending',
                  moneyascend ? 'numascending' : '',
                ]"
                @click.stop="moneyascending"
              ></i>
              <i
                :class="[
                  'sort-caret',
                  'descending',
                  moneydescend ? 'numdescending' : '',
                ]"
                @click.stop="moneydescending"
              ></i>
            </span>
          </td>
        </tr>
      </thead>
      <tbody style="overflow-y: scroll" v-if="isShowP">
        <tr
          :class="['color-333', 'ft-22', 'text-center', 'tbody-tr']"
          v-for="item in tableList"
          :key="item.id"
        >
          <td class="text-left" style="padding-left:1rem;">{{ item.pro_name }}</td>
          <td>{{ item.pro_cat }}</td>
          <td>{{ item.pro_avg_price }}元/{{ item.dw || '' }}</td>
          <td>{{ item.pro_nums }}</td>
          <td>{{ item.pro_price }}</td>
        </tr>
        <div class="mg-tp30 content" v-if="tableList.length == 0">
          <div class="nullStyle">
            <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
          </div>
        </div>
      </tbody>
    </table>
    <div v-if="isShowP" class="flex mg-tp40" style="justify-content:flex-end;">
      <div class="pagination dr-page-box-class" v-if="tableList.length != 0">
        <van-pagination
          v-model="pageform.page"
          :total-items="pageform.count"
          :items-per-page="pageform.limit"
          mode="simple"
          force-ellipses
          style="background-color: #f2f2f2"
          @change="nextpage"
          :class="{'pagination-text-hidden': pageOptions.length > 0,}"
        />
        <!-- <van-dropdown-menu v-if="pageOptions.length > 0" direction="up" class="page-box-dropdown" ref="pageDropdown" :overlay="false" active-color="#1989fa">
          <van-dropdown-item v-model="choose_page" :options="pageOptions" @change="handleChangePage" />
        </van-dropdown-menu> -->
      </div>
    </div>

    <!-- <div :class="['color-333', 'ft-22', 'tbody-tr']" style="font-weight: 600" class="flex nowrap flex-ac"
      v-if="foodtype == 1 && tableList.length != 0">
      <div class="total border-rt-none">合计：</div>
      <div class="total border-rt-none"></div>
      <div class="total border-rt-none"></div>
      <div class="total border-rt-none">{{ food_counts }}</div>
      <div class="total">{{ food_summary }}</div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "salesDetails",
  data() {
    return {
      revenList: [],
      dish_ranking: "",
      pageform: {
        page: 1,
        limit: 6,
        count: 0,
      },
      tableList: [],
      numascend: false,
      numdescend: false,
      moneyascend: false,
      moneydescend: false,
      tabList: [
        { tab_name: '普通商品', type: 1 },
        { tab_name: '称重商品', type: 2 },
        { tab_name: '套餐商品', type: 3 },
      ],
      chooseIndex: 1,
      dishform: {},
      sortType: '',
      pageOptions: [],
      choose_page: 1,
      food_summary: '',
      food_counts: '',
    };
  },
  props: {
    dishData: {
      type: Object,
    },
    queryForm: {
      type: Object,
    },
    isShowP: {
      type: Boolean,
      default: false,
    },
    foodtype: {
      type: Number,
      default: 1,
    }
  },
  mounted() {
    this.chooseIndex = this.foodtype;
    if (this.isShowP) {
      this.setData();
    }
  },
  methods: {
    handleChangePage(e) {
      if (this.pageform.page != e) {
        this.pageform.page = e;
        this.nextpage();
      }
    },
    selectTab(item) {
      if (this.chooseIndex != item.type) {
        this.chooseIndex = item.type;
      }
      this.numascend = false;
      this.numdescend = false;
      this.moneyascend = false;
      this.moneydescend = false;
      this.dish_ranking = '';
      this.$emit('selectTab', { type: this.chooseIndex })
    },
    setData() {
      this.pageOptions = [];
      this.tableList = this.dishData.list || [];
      this.pageform.page = Number(this.dishData.page);
      this.pageform.limit = Number(this.dishData.limit);
      this.pageform.count = Number(this.dishData.count);
      this.food_summary = this.dishData.food_summary;
      this.food_counts = this.dishData.food_counts;
      this.choose_page = this.pageform.page;
    },
    search() {
      let data = {
        food_name: this.dish_ranking,
        page: 1,
        limit: this.pageform.limit,
        start_time: this.dishData.start_time,
        end_time: this.dishData.end_time,
      };
      this.numascend = false;
      this.numdescend = false;
      this.moneyascend = false;
      this.moneydescend = false;
      this.$emit("foodsearch", data);
      this.tableList = [];
      this.pageform.page = 1;
      this.pageform.limit = 6;
      this.pageform.count = 0;
      this.choose_page = this.pageform.page;
    },
    nextpage() {
      let data = {
        page: this.pageform.page,
        limit: this.pageform.limit,
        start_time: this.dishData.start_time,
        end_time: this.dishData.end_time,
      };
      if (this.sortType != '') {
        data['sort_order'] = this.sortType
      }
      if (this.dish_ranking != '') {
        data['food_name'] = this.dish_ranking;
      }
      // console.log('nextpage', data);
      this.$emit("foodnext", data);
      this.tableList = [];
      this.choose_page = this.pageform.page;
    },
    Numberascending() {
      this.numascend = !this.numascend;
      if (this.numascend) {
        this.numdescend = false;
      }
      this.sortType = 1;
      let data = {
        page: 1,
        limit: this.pageform.limit,
        start_time: this.dishData.start_time,
        end_time: this.dishData.end_time,
        sort_order: 'asc',
      };
      if (this.dish_ranking != '') {
        data['food_name'] = this.dish_ranking;
      }
      // console.log('Numberascending', data);
      this.$emit("numsort", data);
      this.choose_page = 1;
    },
    Numberdescending() {
      let sort_order = 2;
      this.moneyascend = false;
      this.moneydescend = false;
      this.numdescend = !this.numdescend;
      if (this.numdescend) {
        this.numascend = false;
      } else {
        sort_order = 1;
      }
      this.sortType = sort_order;
      let data = {
        page: 1,
        limit: this.pageform.limit,
        start_time: this.dishData.start_time,
        end_time: this.dishData.end_time,
        sort_order: 'desc',
      };
      if (this.dish_ranking != '') {
        data['food_name'] = this.dish_ranking;
      }
      // console.log('Numberdescending', data);
      this.$emit("numsort", data);
      this.choose_page = 1;
    },
    moneyascending() {
      let sort_order = 3;
      this.numascend = false;
      this.numdescend = false;
      this.moneyascend = !this.moneyascend;
      if (this.moneyascend) {
        this.moneydescend = false;
      } else {
        sort_order = 1;
      }
      this.sortType = sort_order;
      let data = {
        page: 1,
        limit: this.pageform.limit,
        start_time: this.dishData.start_time,
        end_time: this.dishData.end_time,
        // sort_order: this.sortType,
        sort_price: 'asc'
      };
      if (this.dish_ranking != '') {
        data['food_name'] = this.dish_ranking;
      }
      // console.log('moneyascending', data);
      this.$emit("numsort", data);
      this.choose_page = 1;
    },
    moneydescending() {
      let sort_order = 4;
      this.numascend = false;
      this.numdescend = false;
      this.moneydescend = !this.moneydescend;
      if (this.moneydescend) {
        this.moneyascend = false;
      } else {
        sort_order = 1;
      }
      this.sortType = sort_order;
      let data = {
        page: 1,
        limit: this.pageform.limit,
        start_time: this.dishData.start_time,
        end_time: this.dishData.end_time,
        // sort_order: this.sortType,
        sort_price: 'desc'
      };
      if (this.dish_ranking != '') {
        data['food_name'] = this.dish_ranking;
      }
      // console.log('moneydescending', data);
      this.$emit("numsort", data);
      this.choose_page = 1;
    },
  },
  watch: {
    dishData(newV, oldV) {
      this.dishData = newV;
      this.chooseIndex = this.foodtype;
      this.setData();
    },
  },
};
</script>
<style lang="less" scoped>
.dr-page-box-class {
  position: relative;
  /deep/.van-pagination {
    font-size: 1.9rem;
  }
  .pagination-text-hidden {
    /deep/.van-pagination__page-desc {
      color: #f2f2f2 !important;
    }
  }
  .page-box-dropdown {
    position: absolute;
    top: 0;
    height: 40px;
    background: #f2f2f2;
    width: 31.5%;
    left: 34%;
    color: #646566;
    /deep/.van-dropdown-item {
      width: 3rem;
      left: -0.1rem;
      height: 40vh;
      top: auto;
      position: absolute !important;
      bottom: 40px !important;
    }
    /deep/.van-dropdown-item__content {
      box-shadow: 0 0 0.07rem 0.07rem rgba(229,229,229,0.5);
      max-height: 100%;
      margin: 0 0.1rem;
      width: calc(100% - 0.2rem);
    }
    /deep/.van-dropdown-menu__bar {
      height: 100%;
      background: #f2f2f2;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      color: #646566;
      font-size: 0.26rem;
      padding: 0;
    }
    /deep/.van-dropdown-menu__title--down::after,
    /deep/.van-dropdown-menu__title--active::after {
      display: none;
    }
    /deep/.van-cell {
      font-size: 0.34rem;
      padding: 0.25rem 0.25rem;
    }
    /deep/.van-cell__value {
      flex: none;
    }
  }
}
.revenue-title {
  margin-top: 4rem;
  font-size: 2.6rem;
  color: #333333;
}
.search {
  width: 50%;
  background: #f2f2f2;
  border-radius: 0.8rem;
  height: 5.5rem;
  padding-left: 2rem;
  font-size: 2.4rem;
  margin-right: 2.5rem;
}
.search-button {
  background: #1588F5;
  border-radius: 0.8rem;
  font-size: 2.4rem;
  height: 5.5rem;
  padding: 0 3rem;
  color: #ffffff;
}
table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table {
  background: white;
  position: relative;
  display: table;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    display: block;
    width: 0.6rem;
    height: 0.5rem;
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 0.5rem;
    min-width: 0.4rem;
    border-radius: 0.8rem;
    background-color: rgb(217, 217, 217);
  }

  thead {
    width: calc(100% - 0.6rem);
  }

  thead td {
    border: 0.02rem rgba(0, 0, 0, 0.06) solid;
  }

  tbody {
    overflow-y: scroll;
    display: block;
    height: calc(78vh - 31.85rem);
    width: 100%;
    overflow-x: hidden;
  }

  .tbody-tr {
    overflow-y: scroll;
    height: 7.5rem;
    width: 100%;

    &.odd {
      background: #fef7ef;
    }

    td {
      border: 0.15rem rgba(0, 0, 0, 0.06) solid;
    }
  }
}
.pagination {
  width: 30%;
  float: right;
}
.nullStyle {
  padding-top: 2rem;
  text-align: center;
  img {
    margin: 0 auto;
    width: 2.53rem;
    height: 1.77rem;
  }
}
.caret-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: relative;
}
.sort-caret {
  width: 0;
  height: 0;
  border: 0.8rem solid transparent;
  position: absolute;
  left: 0.5rem;
}
.sort-caret.ascending {
  border-bottom-color: #c0c4cc;
  top: 0;
}
.sort-caret.descending {
  border-top-color: #c0c4cc;
  bottom: 0;
}
.numdescending {
  border-top-color: #409eff !important;
}
.numascending {
  border-bottom-color: #409eff !important;
}
.foodname {
  text-align: left;
}
.total {
  height: 7.5rem;
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
  border: 0.15rem rgba(0, 0, 0, 0.06) solid;
  word-break: break-all;
  text-align: center;
  font-weight: bold;
}

.border-rt-none {
  border-right: none !important;
}
</style>