<template>
  <!-- 客户分析 -->
  <div class="member-profile">
    <div class="revenue-title flex flex-jsb">
      <div class="flex" style="align-items: center">
        <div class="ft-bold" v-if="
          (memberData.start_time != '' || memberData.end_time != '') &&
          isShowP
        ">
          时间：{{ memberData.start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{
              memberData.end_time
          }}
        </div>
      </div>
    </div>
    <div class="flex" style="margin-top: 2.5rem">
      <input type="text" class="search" placeholder="选择客户类型" v-model="member_typename" @click="showPicker = true"
        readonly />

      <van-popup v-model="showPicker" position="bottom" round class="popup-picker-custom">
        <van-picker show-toolbar :columns="memberTypeList" @confirm="onConfirm" @cancel="showPicker = false"
          value-key="type_name" />
      </van-popup>
      <button class="search-button mg-rt20" @click="search">筛 选</button>
      <button class="search-button" @click="reset">重 置</button>
    </div>
    <!-- <ul class="list-box">
      <li class="list-head">
        <div class="color-333 ft-22 text-center ft-bold list-item" v-for="(item, index) in theadSummary"
          :key="index" :class="{'border-rt-none': index != theadSummary.length-1}">
          {{ item }}
        </div>
      </li>
      <li class="list-body">
        <div class="color-333 ft-22 text-center list-body-item ft-bold" v-for="(item, index) in memberList" :key="index">
          <div class="border-rt-none">{{ item.day_time }}</div>
          <div class="border-rt-none">{{ item.member_type }}</div>
          <div class="border-rt-none">{{ item.sale_num }}</div>
          <div class="border-rt-none">{{ item.order_amount }}</div>
          <div class="border-rt-none">{{ item.recharge_num }}</div>
          <div class="border-rt-none">{{ item.recharge_amount }}</div>
          <div>{{ item.give_amount }}</div>
        </div>
        <div class="mg-tp30" v-if="memberList.length === 0">
          <div class="nullStyle">
            <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
          </div>
        </div>
      </li>
    </ul> -->
    <table style="border-collapse: collapse; margin-top: 2.5rem" border="0" cellspacing="0"
      cellpadding="0">
      <thead>
        <tr bgcolor="#F2F2F2" class="thead-tr ft-22" style="height: 7.5rem;">
          <td :class="['color-333', 'ft-22', 'text-center', 'ft-bold']" v-for="(item, index) in theadSummary"
            :key="index">
            {{ item }}
          </td>
        </tr>
      </thead>
      <tbody style="overflow-y: scroll" v-if="isShowP">
        <tr :class="['color-333', 'ft-22', 'text-center', 'tbody-tr', 'ft-bold']" v-for="(item, index) in memberList" :key="index">
          <td>{{ item.day_time }}</td>
          <td>{{ item.member_type }}</td>
          <td>{{ item.sale_num }}</td>
          <td>{{ item.order_amount }}</td>
          <td>{{ item.recharge_num }}</td>
          <td>{{ item.recharge_amount }}</td>
          <td>{{ item.give_amount }}</td>
        </tr>
        <div class="mg-tp30 content" v-if="memberList.length === 0">
          <div class="nullStyle">
            <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
          </div>
        </div>
      </tbody>
    </table>

    <div v-if="isShowP" class="flex mg-tp40" style="justify-content:flex-end;">
      <div class="pagination mp-page-box-class" v-if="memberList.length != 0">
        <van-pagination v-model="pageform.page" :total-items="pageform.count" :items-per-page="pageform.limit"
          mode="simple" force-ellipses style="background-color: #f2f2f2" @change="nextpage"
          :class="{'pagination-text-hidden': pageOptions.length > 0,}" />
      </div>
    </div>

    <table style="border-collapse: collapse;" border="0" cellspacing="0"
      cellpadding="0" v-if="memberList.length != 0">
      <tbody>
        <tr :class="['color-333', 'ft-22', 'text-center', 'tbody-tr', 'ft-bold']">
          <td class="ft-bold">合计：</td>
          <td class="ft-bold"></td>
          <td class="ft-bold">{{ memberform.sale_num }}</td>
          <td class="ft-bold">{{ memberform.order_amount }}</td>
          <td class="ft-bold">{{ memberform.recharge_num }}</td>
          <td class="ft-bold">{{ memberform.recharge_amount }}</td>
          <td class="ft-bold">{{ memberform.give_amount }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>
<script>
export default {
  name: "salesDetails",
  data() {
    return {
      theadSummary: [
        // "序号",
        "时间",
        "客户类型",
        "消费笔数",
        "消费金额",
        "充值笔数",
        "充值金额",
        "赠送金额",
      ],
      memberList: [],
      memberform: {},
      showPicker: false,
      pageform: {
        page: 1,
        limit: 6,
        count: 0,
      },
      member_typename: "",
      member_typeid: -1,
      pageOptions: [],
      choose_page: 1
    };
  },
  props: {
    queryForm: {
      type: Object,
    },
    memberData: {
      type: Object,
    },
    memberTypeList: {
      type: Array,
    },
    isShowP: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.isShowP) {
      this.setData();
    }
  },
  methods: {
    handleChangePage(e) {
      if (this.pageform.page != e) {
        this.pageform.page = e;
        this.nextpage();
      }
    },
    setData() {
      this.pageOptions = [];
      this.memberList = this.memberData.list;
      this.memberform = this.memberData.total_info;
      this.pageform.page = Number(this.memberData.page);
      this.pageform.limit = Number(this.memberData.limit);
      this.pageform.count = Number(this.memberData.count);
      this.choose_page = this.pageform.page;
    },
    search() {
      let data = {
        page: 1,
        limit: this.pageform.limit,
        member_action: this.member_typeid,
      };
      this.$emit("memberSearch", data);
      this.choose_page = 1;
    },
    onConfirm(value) {
      this.member_typename = value.type_name;
      this.member_typeid = value.id;
      this.showPicker = false;
    },
    reset() {
      this.member_typename = "";
      this.member_typeid = -1;
      this.$emit("memberreset");
    },
    nextpage() {
      let data = {
        page: this.pageform.page,
        limit: this.pageform.limit,
        start_time: this.memberData.start_time,
        end_time: this.memberData.end_time,
        member_action: this.member_typeid,
      };
      this.$emit("nextmember", data);
      this.memberList = [];
      this.memberform = {};
      this.choose_page = this.pageform.page;
    },
  },
  watch: {
    memberData(newV, oldV) {
      this.memberData = newV;
      this.setData();
    },
  },
};
</script>
<style lang="less" scoped>
.member-profile {
  width: 100%;
}
.mp-page-box-class {
  position: relative;
  /deep/.van-pagination {
    font-size: 1.9rem;
  }
  .pagination-text-hidden {
    /deep/.van-pagination__page-desc {
      color: #f2f2f2 !important;
    }
  }
  .page-box-dropdown {
    position: absolute;
    top: 0;
    height: 40px;
    background: #f2f2f2;
    width: 31.5%;
    left: 34%;
    color: #646566;
    /deep/.van-dropdown-item {
      width: 3rem;
      left: -0.1rem;
      height: 40vh;
      top: auto;
      position: absolute !important;
      bottom: 40px !important;
    }
    /deep/.van-dropdown-item__content {
      box-shadow: 0 0 0.07rem 0.07rem rgba(229,229,229,0.5);
      max-height: 100%;
      margin: 0 0.1rem;
      width: calc(100% - 0.2rem);
    }
    /deep/.van-dropdown-menu__bar {
      height: 100%;
      background: #f2f2f2;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      color: #646566;
      font-size: 0.26rem;
      padding: 0;
    }
    /deep/.van-dropdown-menu__title--down::after,
    /deep/.van-dropdown-menu__title--active::after {
      display: none;
    }
    /deep/.van-cell {
      font-size: 0.34rem;
      padding: 0.25rem 0.25rem;
    }
    /deep/.van-cell__value {
      flex: none;
    }
  }
}
.revenue-title {
  margin-top: 4rem;
  font-size: 2.6rem;
  color: #333333;
}

table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;

  td {
    word-break: break-all;
  }
}

table {
  background: white;
  position: relative;
  display: table;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    display: block;
    width: 0.6rem;
    height: 0.5rem;
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 0.5rem;
    min-width: 0.4rem;
    border-radius: 0.8rem;
    background-color: rgb(217, 217, 217);
  }

  thead {
    width: calc(100% - 0.6rem);
  }

  thead td {
    border: 0.15rem rgba(0, 0, 0, 0.06) solid;
  }

  tbody {
    overflow-y: scroll;
    display: block;
    height: calc(78vh - 38.85rem);
    width: 100%;
    overflow-x: hidden;
  }

  .tbody-tr {
    overflow-y: scroll;
    height: 7.5rem;
    width: 100%;

    &.odd {
      background: #fef7ef;
    }

    td {
      border: 0.15rem rgba(0, 0, 0, 0.06) solid;
    }
  }
}

.search {
  width: 50%;
  background: #f2f2f2;
  border-radius: 0.8rem;
  height: 5.5rem;
  padding-left: 2rem;
  font-size: 2.4rem;
  margin-right: 2.5rem;
}

.search-button {
  background: #1588F5;
  border-radius: 0.8rem;
  font-size: 2.4rem;
  height: 5.5rem;
  padding: 0 3rem;
  color: #ffffff;
}

.nullStyle {
  padding-top: 2rem;
  text-align: center;
}

.pagination {
  width: 30%;
  // float: right;
}

.total {
  height: 7.5rem;
  display: flex;
  width: 32.63rem;
  justify-content: center;
  align-items: center;
  border: 0.15rem rgba(0, 0, 0, 0.06) solid;
  word-break: break-all;
  text-align: center;
  font-weight: bold;
  flex: 1;
}

.border-rt-none {
  border-right: none !important;
}
</style>