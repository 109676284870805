<template>
  <!-- 客户分析 -->
  <div>
    <div class="revenue-title flex flex-jsb">
      <div class="flex" style="align-items: center">
        <div v-if="isShowP">
          时间：{{ start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{ end_time || '' }}
        </div>
      </div>
      <!-- <button class="export">导 出</button> -->
    </div>
    <div class="flex" style="margin-top: 0.4rem">
      <input type="text" class="search mg-rt30" placeholder="请输入商品名称或助记码" v-model="keywords" />

      <input type="text" class="search" placeholder="选择库存变动类型" v-model="change_type_name" @click="showPicker = true" readonly />

      <van-popup v-model="showPicker" position="bottom" round class="popup-picker-custom">
        <van-picker show-toolbar :columns="changeTypeOptions" @confirm="onConfirm" @cancel="showPicker = false" value-key="type" />
      </van-popup>

      <button class="search-button" @click="search">筛 选</button>
      <button class="search-button" @click="reset">重 置</button>
    </div>
    <table width="100%" style="border-collapse: collapse; margin-top: 0.4rem" border="0" cellspacing="0"
      cellpadding="0">
      <thead>
        <tr bgcolor="#F2F2F2" class="thead-tr ft-38" style="height: 1.4rem; font-weight: 600">
          <td :class="['color-333', 'ft-34', 'text-center']" v-for="(item, index) in theadSummary"
            :key="index">
            {{ item }}
          </td>
        </tr>
      </thead>
      <tbody style="overflow-y: scroll" v-if="isShowP">
        <tr :class="['color-333', 'ft-30', 'text-center', 'tbody-tr']" v-for="(item, index) in stockLogList" :key="index">
          <!-- <td>{{ item.id }}</td> -->
          <td>{{ item.create_time }}</td>
          <td>{{ item.food_name }}</td>
          <td>{{ item.food_spec }}</td>
          <td>
            {{ item.change_type | ChangeType }}
            <van-icon v-if="item.change_type == 2 && item.content && item.content != ''" 
              style="vertical-align: text-top;" name="info" color="#666" size="0.36rem" class="mg-lt5"
              @click="openDialog(item, 'change_remark')"
            />
          </td>
          <td>{{ item.front }}</td>
          <td>{{ item.type == 1 ? '+' : '-' }}{{ item.num }}</td>
          <td>{{ item.after }}</td>
          <td>{{ item.admin_type == 3 ? '小程序用户' : (item.admin_name || '') }}</td>
        </tr>
        <div class="mg-tp30 content" v-if="stockLogList.length === 0">
          <div class="nullStyle">
            <p class="text-center ft-40 color-999 mg-tp30">暂无数据~</p>
          </div>
        </div>
      </tbody>
    </table>

    <div v-if="isShowP" class="flex mg-tp40" style="justify-content:flex-end;">
      <div class="pagination rd-page-box-class" v-if="stockLogList.length != 0">
        <van-pagination v-model="pageform.page" :total-items="pageform.count" :items-per-page="pageform.limit"
          mode="simple" force-ellipses style="background-color: #f2f2f2" @change="nextpage"
          :class="{'pagination-text-hidden': pageOptions.length > 0,}" />
        <van-dropdown-menu v-if="pageOptions.length > 0" direction="up" class="page-box-dropdown" ref="pageDropdown" :overlay="false" active-color="#1989fa">
          <van-dropdown-item v-model="choose_page" :options="pageOptions" @change="handleChangePage" />
        </van-dropdown-menu>
      </div>
    </div>

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      :showCancelBtn="false"
      :showConfirmBtn="false"
      @closePopDialog="closePopDialog"
    ></popupOfDialog>

  </div>
</template>
<script>
import popupOfDialog from '../component/popupOfDialog.vue';
export default {
  name: "recharge-details",
  components: {
    popupOfDialog
  },
  data() {
    return {
      theadSummary: [
        // "序号",
        "时间",
        "商品名称",
        "商品规格",
        "变动类型",
        "操作前库存",
        "变动库存",
        "操作后库存",
        "操作人",
      ],
      stockLogList: [],
      pageform: {
        page: 1,
        limit: 6,
        count: 0,
      },
      keywords: "",
      start_time: '',
      end_time: '',
      pageOptions: [],
      choose_page: 1,
      // 1初始库存 2手动调整 3销售单 4退菜
      changeTypeOptions: [
        { type: '初始库存', value: 1, },
        { type: '销售单', value: 3 },
        { type: '退菜', value: 4 },
        { type: '手动调整', value: 2 },
      ],
      change_type: '',
      change_type_name: '',
      showPicker: false,
      showDialog: false,
      dialogType: '',
      dialogTitle: '',
      dialogMsg: '',
      dialogData: {},
    };
  },
  filters : {
    ChangeType(val) {
      const obj = {
        1: '初始库存',
        2: '手动调整',
        3: '销售单',
        4: '退菜'
      }
      return obj[val] || '';
    }
  },
  props: {
    queryForm: {
      type: Object,
    },
    stockLogdata: {
      type: Object,
    },
    isShowP: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
    },
    limit: {
      type: Number,
    }
  },
  mounted() {
    if (this.isShowP) {
      this.setData();
    }
  },
  methods: {
    closePopDialog() {
      this.showDialog = false;
    },
    openDialog(item, type) {
      this.dialogType = type;
      this.dialogTitle = '备注';
      this.dialogMsg = item.content;
      this.showDialog = true;
    },
    onConfirm(value) {
      this.change_type_name = value.type;
      this.change_type = value.value;
      this.showPicker = false;
    },
    handleChangePage(e) {
      console.log(this.pageform.page, e);
      if (this.pageform.page != e) {
        this.pageform.page = e;
        this.nextpage();
      }
    },
    setData() {
      this.pageOptions = [];
      this.stockLogList = this.stockLogdata.data;
      this.pageform.page = Number(this.page);
      this.pageform.limit = Number(this.limit);
      this.pageform.count = Number(this.stockLogdata.count);
      this.start_time = this.stockLogdata.time_start || '';
      this.end_time = this.stockLogdata.time_end || '';
      this.choose_page = this.pageform.page;
    },
    search() {
      let data = {
        page: 1,
        limit: this.pageform.limit,
        name: this.keywords,
        change_type: this.change_type,
      };
      this.choose_page = 1;
      this.stockLogList = [];
      this.$emit("filterStockLog", data);
    },
    reset() {
      this.keywords = "";
      this.change_type = '';
      this.change_type_name = '';
      this.search();
      this.stockLogList = [];
    },
    nextpage() {
      this.stockLogList = [];
      let data = {
        page: this.pageform.page,
        limit: this.pageform.limit,
        search_content: this.keywords,
      };
      this.choose_page = this.pageform.page;
      this.$emit("filterStockLog", data);
    },
  },
  watch: {
    stockLogdata(newV, oldV) {
      this.stockLogdata = newV;
      this.setData();
    },
  },
};
</script>
<style lang="less" scoped>
.rd-page-box-class {
  position: relative;
  /deep/.van-pagination {
    font-size: 0.26rem;
  }
  .pagination-text-hidden {
    /deep/.van-pagination__page-desc {
      color: #f2f2f2 !important;
    }
  }
  .page-box-dropdown {
    position: absolute;
    top: 0;
    height: 40px;
    background: #f2f2f2;
    width: 31.5%;
    left: 34%;
    color: #646566;
    /deep/.van-dropdown-item {
      width: 3rem;
      left: -0.1rem;
      height: 40vh;
      top: auto;
      position: absolute !important;
      bottom: 40px !important;
    }
    /deep/.van-dropdown-item__content {
      box-shadow: 0 0 0.07rem 0.07rem rgba(229,229,229,0.5);
      max-height: 100%;
      margin: 0 0.1rem;
      width: calc(100% - 0.2rem);
    }
    /deep/.van-dropdown-menu__bar {
      height: 100%;
      background: #f2f2f2;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      color: #646566;
      font-size: 0.26rem;
      padding: 0;
    }
    /deep/.van-dropdown-menu__title--down::after,
    /deep/.van-dropdown-menu__title--active::after {
      display: none;
    }
    /deep/.van-cell {
      font-size: 0.34rem;
      padding: 0.25rem 0.25rem;
    }
    /deep/.van-cell__value {
      flex: none;
    }
  }
}
.revenue-title {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 0.4rem;
  color: #333333;
}

.export {
  width: 1.65rem;
  height: 0.85rem;
  background: #fe724f;
  border-radius: 0.13rem;
  color: #fff;
  font-weight: 400;
  font-size: 0.43rem;
}

table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;

  td {
    word-break: break-word;
  }
}

table {
  background: white;
  position: relative;
  display: table;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    display: block;
    width: 0.06rem;
    height: 0.05rem;
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 0.05rem;
    min-width: 0.04rem;
    border-radius: 0.13rem;
    background-color: rgb(217, 217, 217);
  }

  thead {
    width: calc(100% - 0.07rem);
  }

  thead td {
    border: 0.02rem rgba(0, 0, 0, 0.06) solid;
  }

  tbody {
    overflow-y: scroll;
    display: block;
    height: calc(78vh - 5.85rem);
    width: 100%;
  }

  .tbody-tr {
    overflow-y: scroll;
    height: 1rem;
    width: 100%;

    &.odd {
      background: #fef7ef;
    }

    td {
      height: 1.41rem;
      border: 0.02rem rgba(0, 0, 0, 0.06) solid;
    }
  }

  .right-content {
    .line2 {
      margin-top: 0.21rem;
    }
  }
}

.search {
  width: 30%;
  background: #f2f2f2;
  border-radius: 0.13rem;
  height: 0.75rem;
  padding-left: 0.3rem;
  font-size: 0.36rem;
}

.search-button {
  background: #fe724f;
  border-radius: 0.13rem;
  height: 0.75rem;
  padding: 0 0.35rem;
  font-size: 0.36rem;
  color: #ffffff;
  margin-left: 0.3rem;
}

.nullStyle {
  padding-top: 2rem;
  text-align: center;

  img {
    margin: 0 auto;
    width: 2.53rem;
    height: 1.77rem;
  }
}

.pagination {
  width: 30%;
  // float: right;
}

.total {
  height: 1.41rem;
  display: flex;
  width: 3.263rem;
  justify-content: center;
  align-items: center;
  border: 0.02rem rgba(0, 0, 0, 0.06) solid;
  word-break: break-word;
  text-align: center;
}

.border-rt-none {
  border-right: none !important;
}
</style>