<template>
  <!-- 订单明细 -->
  <div>
    <div class="revenue-title flex flex-jsb">
      <div class="flex" style="align-items: center">
        <div
          v-if="
            (saleData.start_time != '' || saleData.end_time != '') && isShowP
          "
          class="ft-bold"
        >
          时间：{{ saleData.start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{
            saleData.end_time
          }}
        </div>
      </div>
    </div>
    <div class="flex" style="margin-top: 2.5rem">
      <input
        type="text"
        class="search"
        placeholder="请输入订单号"
        v-model="order_num"
        @keyup.enter="search"
      />
      <button class="search-button" @click="search">搜 索</button>
    </div>
    <table
      width="100%"
      style="border-collapse: collapse; margin-top: 2.5rem"
      border="0"
      cellspacing="0"
      cellpadding="0"
    >
      <thead>
        <tr
          bgcolor="#F2F2F2"
          class="thead-tr ft-24"
          style="height: 7rem;"
        >
          <td
            :class="[
              'color-333',
              'ft-22',
              'text-center',
              index == 0 ? 'orderNumberwidth' : '',
              index == 1 ? 'timewidth' : '',
              'ft-bold'
            ]"
            v-for="(item, index) in theadSummary"
            :key="index"
          >
            {{ item }}
          </td>
        </tr>
      </thead>
      <tbody v-if="isShowP">
        <tr
          :class="['color-333', 'ft-22', 'text-center', 'tbody-tr']"
          v-for="item in tableList"
          :key="item.id"
        >
          <td class="orderNumberwidth">{{ item.order_num }}</td>
          <td class="timewidth">{{ item.pay_time }}</td>
          <td>{{ item.change_price }}</td>
          <td style="line-height: 1.3; padding: 0.55rem 0;">
            <template v-if="item.discountPrice.length > 0">
              <div v-for="item in item.discountPrice">{{ item }}</div>
            </template>
            <div v-else>0</div>
          </td>
          <td>{{ item.return_money }}</td>
          <td>{{ item.total_price }}</td>
        </tr>
        <div class="mg-tp30 content" v-if="tableList.length == 0">
          <div class="nullStyle">
            <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
          </div>
        </div>
      </tbody>
    </table>
    <div class="flex mg-tp40" style="justify-content: flex-end;">
      <div class="pagination sd-page-box-class" v-if="isShowP && tableList.length != 0">
        <van-pagination
          v-model="pageform.page"
          :total-items="pageform.count"
          :items-per-page="pageform.limit"
          mode="simple"
          force-ellipses
          style="background-color: #f2f2f2"
          @change="nextpage"
          :class="{'pagination-text-hidden': pageOptions.length > 0,}"
        />
        <!-- <van-dropdown-menu v-if="pageOptions.length > 0" direction="up" class="page-box-dropdown" ref="pageDropdown" :overlay="false" active-color="#1989fa">
          <van-dropdown-item v-model="choose_page" :options="pageOptions" @change="handleChangePage" />
        </van-dropdown-menu> -->
      </div>
    </div>
    <table
      width="100%"
      style="border-collapse: collapse;"
      border="0"
      cellspacing="0"
      cellpadding="0"
      v-if="tableList.length != 0"
    >
      <tbody>
        <tr
          :class="['color-333', 'ft-22', 'text-center', 'tbody-tr']"
        >
          <td class="orderNumberwidth ft-bold">合计：</td>
          <td class="timewidth ft-bold"></td>
          <td class="ft-bold">{{ totalinfo.total_change_price }}</td>
          <td class="ft-bold">{{ totalinfo.total_discount_amount }}</td>
          <td class="ft-bold">{{ totalinfo.total_return_price }}</td>
          <td class="ft-bold">{{ totalinfo.total_total_price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: "salesDetails",
  data() {
    return {
      theadSummary: [
        "订单号",
        "时间",
        "原价",
        "优惠",
        "退款",
        "实收",
      ],
      tableList: [],
      totalinfo: {},
      pageform: {
        page: 1,
        limit: 6,
        count: 0,
      },
      order_num: "",
      pageOptions: [],
      choose_page: 1
    };
  },
  props: {
    saleData: {
      type: Object,
    },
    queryForm: {
      type: Object,
    },
    isShowP: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  mounted() {
    if (this.isShowP) {
      this.setData();
    }
  },
  methods: {
    handleChangePage(e) {
      if (this.pageform.page != e) {
        this.pageform.page = e;
        this.nextpage();
      }
    },
    setData() {
      this.pageOptions = [];
      // console.log('销售', this.saleData);
      this.tableList = this.saleData.list;
      this.totalinfo = this.saleData.total_info;
      this.pageform.page = Number(this.saleData.page);
      this.pageform.limit = Number(this.saleData.limit);
      this.pageform.count = Number(this.saleData.count);
      this.choose_page = this.pageform.page;
      this.tableList.forEach(element => {
        let discountPrice = []
        if (element.discount_amount > 0) {
          discountPrice.push(`会员优惠:${element.discount_amount}`);
        }
        if (element.discount_rate_price > 0) {
          discountPrice.push(`折扣金额:${element.discount_rate_price}`);
        }
        if (element.wipe_price > 0) {
          discountPrice.push(`抹零金额:${element.wipe_price}`);
        }
        if (element.score_discount > 0) {
          discountPrice.push(`积分抵扣:${element.score_discount}`);
        }
        if (element.coupon_price > 0) {
          discountPrice.push(`优惠券:${element.coupon_price}`);
        }
        element.discountPrice = discountPrice;
      });
    },
    search() {
      let data = {
        order_num: this.order_num,
        page: 1,
        limit: this.pageform.limit,
      };
      this.$emit("filterSaleReport", data);
      this.tableList = [];
      this.totalinfo = {};
      this.pageform.page = 1;
      this.pageform.limit = 6;
      this.pageform.count = 0;
      this.choose_page = this.pageform.page;
    },
    nextpage() {
      let data = {
        page: this.pageform.page,
        limit: this.pageform.limit,
        start_time: this.saleData.start_time,
        end_time: this.saleData.end_time,
      };
      this.choose_page = this.pageform.page;
      this.$emit("filterSaleReport", data);
      this.tableList = [];
      this.totalinfo = {};
    },
  },
  watch: {
    saleData(newV, oldV) {
      this.saleData = newV;
      this.setData();
    },
  },
};
</script>
<style lang="less" scoped>
.sd-page-box-class {
  position: relative;
  /deep/.van-pagination {
    font-size: 1.9rem;
  }
  .pagination-text-hidden {
    /deep/.van-pagination__page-desc {
      color: #f2f2f2 !important;
    }
  }
  .page-box-dropdown {
    position: absolute;
    top: 0;
    height: 40px;
    background: #f2f2f2;
    width: 31.5%;
    left: 34%;
    color: #646566;
    /deep/.van-dropdown-item {
      width: 3rem;
      left: -0.1rem;
      height: 40vh;
      top: auto;
      position: absolute !important;
      bottom: 40px !important;
    }
    /deep/.van-dropdown-item__content {
      box-shadow: 0 0 0.07rem 0.07rem rgba(229,229,229,0.5);
      max-height: 100%;
      margin: 0 0.1rem;
      width: calc(100% - 0.2rem);
    }
    /deep/.van-dropdown-menu__bar {
      height: 100%;
      background: #f2f2f2;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      color: #646566;
      font-size: 0.26rem;
      padding: 0;
    }
    /deep/.van-dropdown-menu__title--down::after,
    /deep/.van-dropdown-menu__title--active::after {
      display: none;
    }
    /deep/.van-cell {
      font-size: 0.34rem;
      padding: 0.25rem 0.25rem;
    }
    /deep/.van-cell__value {
      flex: none;
    }
  }
}
.otherwidth {
  width: 2.7rem;
}
.revenue-title {
  margin-top: 4rem;
  font-size: 2.6rem;
  color: #333333;
}

.search {
  width: 50%;
  background: #f2f2f2;
  border-radius: 0.8rem;
  height: 5.5rem;
  padding-left: 2rem;
  font-size: 2.4rem;
  margin-right: 2.5rem;
}

.search-button {
  background: #1588F5;
  border-radius: 0.8rem;
  font-size: 2.4rem;
  height: 5.5rem;
  padding: 0 3rem;
  color: #ffffff;
}

table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;

  td {
    word-break: break-all;
  }
}

table {
  background: white;
  position: relative;
  display: table;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    display: block;
    width: 0.6rem;
    height: 0.5rem;
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 0.5rem;
    min-width: 0.4rem;
    border-radius: 0.8rem;
    background-color: rgb(217, 217, 217);
  }

  thead {
    width: calc(100% - 0.6rem);
  }

  thead td {
    border: 0.15rem rgba(0, 0, 0, 0.06) solid;
  }

  tbody {
    overflow-y: scroll;
    display: block;
    height: calc(78vh - 38.85rem);
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }

  .tbody-tr {
    overflow-y: scroll;
    height: 7.5rem;
    width: 100%;

    &.odd {
      background: #fef7ef;
    }

    td {
      border: 0.15rem rgba(0, 0, 0, 0.06) solid;
    }
  }

  .right-content {
    .line2 {
      margin-top: 0.21rem;
    }
  }
}

.pagination {
  width: 30%;
  float: right;
}

/deep/.van-pagination__item--active {
  background-color: #1588F5;
}

.orderNumberwidth {
  width: 29rem;
}

.timewidth {
  width: 23rem;
  word-break: break-word;
}

.nullStyle {
  padding-top: 2rem;
  text-align: center;

  img {
    margin: 0 auto;
    width: 2.53rem;
    height: 1.77rem;
  }
}

.total {
  height: 1.41rem;
  display: flex;
  width: 2.403rem;
  justify-content: center;
  align-items: center;
  border: 0.02rem rgba(0, 0, 0, 0.06) solid;
  word-break: break-all;
  text-align: center;
}

.border-rt-none {
  border-right: none !important;
}

.ts-width-ft {
  height: 1.41rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.02rem rgba(0, 0, 0, 0.06) solid;
}
</style>