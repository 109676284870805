<template>
  <!-- 客户分析 -->
  <div>
    <div class="revenue-title flex flex-jsb">
      <div class="flex" style="align-items: center">
        <div v-if="isShowP" class="ft-bold">
          登录时间：{{ start_time }} &nbsp;&nbsp;至&nbsp;&nbsp;{{ end_time || '' }}
        </div>
      </div>
    </div>
    <div class="flex mg-tp25">
      <input type="text" class="search" placeholder="请输入收银员姓名" v-model="keywords" />

      <button class="search-button mg-rt25" @click="search">筛 选</button>
      <button class="search-button" @click="reset">重 置</button>
    </div>
    <table width="100%" class="mg-tp25" style="border-collapse: collapse;" border="0" cellspacing="0"
      cellpadding="0">
      <thead>
        <tr bgcolor="#F2F2F2" class="thead-tr ft-38" style="height: 7.5rem;">
          <td :class="['color-333', 'ft-22', 'text-center', 'ft-bold']" v-for="(item, index) in theadSummary"
            :key="index">
            {{ item }}
          </td>
        </tr>
      </thead>
      <tbody style="overflow-y: scroll" v-if="isShowP">
        <tr :class="['color-333', 'ft-22', 'text-center', 'tbody-tr']" v-for="(item, index) in shift_list" :key="index">
          <td>{{ item.s_time }}</td>
          <td>{{ item.e_time }}</td>
          <td>{{ item.staff_name }}</td>
          <td>{{ item.total_price }}</td>
          <td>{{ item.cost_price }}</td>
          <td>{{ item.member_price }}</td>
          <td>{{ item.member_count }}</td>
          <td>{{ item.order_count }}</td>
          <td>{{ item.recharge_count }}</td>
        </tr>
        <div class="mg-tp30 content" v-if="shift_list.length === 0">
          <div class="nullStyle">
            <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
          </div>
        </div>
      </tbody>
    </table>

    <div v-if="isShowP" class="flex mg-tp40" style="justify-content:flex-end;">
      <div class="pagination rd-page-box-class" v-if="shift_list.length != 0">
        <van-pagination v-model="pageform.page" :total-items="pageform.count" :items-per-page="pageform.limit"
          mode="simple" force-ellipses style="background-color: #f2f2f2" @change="nextpage"
          :class="{'pagination-text-hidden': pageOptions.length > 0,}" />
        <van-dropdown-menu v-if="pageOptions.length > 0" direction="up" class="page-box-dropdown" ref="pageDropdown" :overlay="false" active-color="#1989fa">
          <van-dropdown-item v-model="choose_page" :options="pageOptions" @change="handleChangePage" />
        </van-dropdown-menu>
      </div>
    </div>

    <table width="100%" style="border-collapse: collapse;" border="0" cellspacing="0"
      cellpadding="0" v-if="shift_list.length != 0">
      <tbody>
        <tr :class="['color-333', 'ft-22', 'text-center', 'tbody-tr']">
          <td class="ft-bold">合计：</td>
          <td></td>
          <td></td>
          <td class="ft-bold">{{ shiftData.total_price || '0' }}</td>
          <td class="ft-bold">{{ shiftData.cost_price || '0' }}</td>
          <td class="ft-bold">{{ shiftData.member_price || '0' }}</td>
          <td class="ft-bold">{{ shiftData.member_count || '0' }}</td>
          <td class="ft-bold">{{ shiftData.order_count || '0' }}</td>
          <td class="ft-bold">{{ shiftData.recharge_count || '0' }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>
<script>
export default {
  name: "recharge-details",
  data() {
    return {
      theadSummary: [
        "登录时间",
        "交班时间",
        "收银员",
        "销售额",
        "毛利额",
        "会员储值",
        "新增会员",
        "订单数量",
        "充值笔数",
      ],
      shift_list: [],
      pageform: {
        page: 1,
        limit: 6,
        count: 0,
      },
      keywords: "",
      start_time: '',
      end_time: '',
      pageOptions: [],
      choose_page: 1
    };
  },
  filters: {
    // cash = 现金   weixin  = 微信  alipay = 支付宝  wallet = 会员余额   unionpay = 云闪付  null = 其他 
    PayType(val) {
      let obj = {
        'cash': '现金',
        'weixin': '微信',
        'alipay': '支付宝',
        'wallet': '会员余额',
        'unionpay': '云闪付',
      }
      return obj[val] || '';
    }
  },
  props: {
    queryForm: {
      type: Object,
    },
    shiftData: {
      type: Object,
    },
    isShowP: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    console.log(this.isShowP);
    if (this.isShowP) {
      this.setData();
    }
  },
  methods: {
    handleChangePage(e) {
      if (this.pageform.page != e) {
        this.pageform.page = e;
        this.nextpage();
      }
    },
    setData() {
      console.log('0000');
      this.pageOptions = [];
      this.shift_list = this.shiftData.list;
      this.pageform.page = Number(this.shiftData.page);
      this.pageform.limit = Number(this.shiftData.limit);
      this.pageform.count = Number(this.shiftData.count);
      this.start_time = this.shiftData.start_time || '';
      this.end_time = this.shiftData.end_time || '';
      this.choose_page = this.pageform.page;
    },
    search() {
      let data = {
        page: 1,
        limit: this.pageform.limit,
        search_content: this.keywords,
      };
      this.choose_page = 1;
      this.$emit("filterShift", data);
    },
    reset() {
      this.keywords = "";
      this.search();
      this.shift_list = [];
    },
    nextpage() {
      this.shift_list = [];
      let data = {
        page: this.pageform.page,
        limit: this.pageform.limit,
        search_content: this.keywords,
      };
      this.choose_page = this.pageform.page;
      this.$emit("filterShift", data);
    },
  },
  watch: {
    shiftData(newV, oldV) {
      this.shiftData = newV;
      this.setData();
    },
  },
};
</script>
<style lang="less" scoped>
.rd-page-box-class {
  position: relative;
  /deep/.van-pagination {
    font-size: 1.9rem;
  }
  .pagination-text-hidden {
    /deep/.van-pagination__page-desc {
      color: #f2f2f2 !important;
    }
  }
  .page-box-dropdown {
    position: absolute;
    top: 0;
    height: 40px;
    background: #f2f2f2;
    width: 31.5%;
    left: 34%;
    color: #646566;
    /deep/.van-dropdown-item {
      width: 3rem;
      left: -0.1rem;
      height: 40vh;
      top: auto;
      position: absolute !important;
      bottom: 40px !important;
    }
    /deep/.van-dropdown-item__content {
      box-shadow: 0 0 0.07rem 0.07rem rgba(229,229,229,0.5);
      max-height: 100%;
      margin: 0 0.1rem;
      width: calc(100% - 0.2rem);
    }
    /deep/.van-dropdown-menu__bar {
      height: 100%;
      background: #f2f2f2;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      color: #646566;
      font-size: 0.26rem;
      padding: 0;
    }
    /deep/.van-dropdown-menu__title--down::after,
    /deep/.van-dropdown-menu__title--active::after {
      display: none;
    }
    /deep/.van-cell {
      font-size: 0.34rem;
      padding: 0.25rem 0.25rem;
    }
    /deep/.van-cell__value {
      flex: none;
    }
  }
}
.revenue-title {
  margin-top: 4rem;
  font-size: 2.6rem;
  color: #333333;
}

table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;

  td {
    word-break: break-word;
  }
}

table {
  background: white;
  position: relative;
  display: table;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    display: block;
    width: 0.6rem;
    height: 0.5rem;
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 0.5rem;
    min-width: 0.4rem;
    border-radius: 0.8rem;
    background-color: rgb(217, 217, 217);
  }

  thead {
    width: calc(100% - 0.6rem);
  }

  thead td {
    border: 0.02rem rgba(0, 0, 0, 0.06) solid;
  }

  tbody {
    overflow-y: scroll;
    display: block;
    height: calc(78vh - 38.85rem);
    width: 100%;
    overflow-x: hidden;
  }

  .tbody-tr {
    overflow-y: scroll;
    height: 7.5rem;
    width: 100%;

    &.odd {
      background: #fef7ef;
    }

    td {
      border: 0.15rem rgba(0, 0, 0, 0.06) solid;
    }
  }

  .right-content {
    .line2 {
      margin-top: 0.21rem;
    }
  }
}

.search {
  width: 50%;
  background: #f2f2f2;
  border-radius: 0.8rem;
  height: 5.5rem;
  padding-left: 2rem;
  font-size: 2.4rem;
  margin-right: 2.5rem;
}

.search-button {
  background: #1588F5;
  border-radius: 0.8rem;
  font-size: 2.4rem;
  height: 5.5rem;
  padding: 0 3rem;
  color: #ffffff;
}

.nullStyle {
  padding-top: 2rem;
  text-align: center;

  img {
    margin: 0 auto;
    width: 2.53rem;
    height: 1.77rem;
  }
}

.pagination {
  width: 30%;
  // float: right;
}

.total {
  height: 7.5rem;
  display: flex;
  width: 32.63rem;
  justify-content: center;
  align-items: center;
  border: 0.15rem rgba(0, 0, 0, 0.06) solid;
  word-break: break-word;
  text-align: center;
  flex: 1;
}

.border-rt-none {
  border-right: none !important;
}
</style>